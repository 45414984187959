<template>
    <CRow class="justify-content-center">
        <CModal title="Aviso!" color="danger" :show.sync="showAlert">
            {{ msg }}
            <template #footer>
                <CButton @click="showAlert = false" color="danger">OK</CButton>
            </template>
        </CModal>
        <CCol sm="12">
            <CCard>
                <CCardHeader>
                    <CRow>
                        <CCol sm="12">
                            <h4 id="traffic" class="card-title mb-0">Tokens</h4>
                            <div class="small text-muted">Todos os tokens ativos de clientes</div>
                        </CCol>
                    </CRow>
                </CCardHeader>
                <CCardBody>
                    <CRow class="align-items-center">
                        <CCol  sm="12" class="mb-3 mb-xl-3">
                            <CRow>
                                <CCol sm="6" md="4">
                                    <CButton color="primary" @click="getData();">Atualizar</CButton>
                                </CCol>                               
                            </CRow>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12">
                            <CDataTable
                                class="mb-0 table-outline"
                                hover
                                head-color="light"
                                :items="tokens"
                                :fields="tableFields"
                                :noItemsView="noItemsView"
                            >                                
                                <td slot="username" slot-scope="{item}">
                                    {{item.username}}
                                </td>
                                <td slot="token" slot-scope="{item}">
                                    {{item.token}}
                                </td>
                                <td slot="phone_mask" slot-scope="{item}">
                                    {{item.phone_mask}}
                                </td>                                
                                <td slot="cnpj" slot-scope="{item}">
                                    {{formatCNPJ(item.cnpj)}}
                                </td>
                                <td slot="company_name" slot-scope="{item}">
                                    {{item.company_name}}
                                </td>
                                <td slot="fantasy_name" slot-scope="{item}">
                                    {{item.fantasy_name}}
                                </td>
                                <td slot="expires_in" slot-scope="{item}">
                                    {{ calculateRemainingMinutes(item.expires_in) }} minutos.
                                </td>
                            </CDataTable>
                        </CCol>
                    </CRow>                    
                </CCardBody>
            </CCard>
        </CCol>
    </CRow>
</template>

<script>
import functions from "@/functions";
const axios = require("axios").default;

export default {
    name: 'TokensClientes',
    data () {
        return {
            showAlert: false,
            msg: '',
            form: {},            
            tpNovaConta:'',
            bancoNovaConta: '',
            numeroNovaConta: '',
            digitoNumeroNovaConta: '',
            agenciaNovaConta: '',
            digitoAgenciaNovaConta: '',            
            tableFields: [
                { key: 'username', label: 'Usuário' },
                { key: 'token', label: 'Token' },
                { key: 'phone_mask',  label: 'Celular' },                
                { key: 'cnpj', label: 'CNPJ' },
                { key: 'company_name', label: 'Razao' },
                { key: 'fantasy_name', label: 'Fantasia' },
                { key: 'expires_in', label: 'Tempo restante:' },
            ],
            tokens:[],
            noItemsView:{ 
                noResults: 'Nenhum resultado para este filtro.', 
                noItems: 'Nenhuma token gerado!' 
            },            
        }
    },
    methods: {
        ...functions,
        getData: async function () {
            console.log('getData');
            const keyAccess = localStorage.getItem('user-token');
            try {
                const resp = await axios({
                    method: 'get',
                    url: this.vhost('transaction/clients/tokens'),
                    headers: { "Content-Type": "application/json","x-access-token": keyAccess},
                })
                if (resp.data.data && resp.data.data.length>0){
                    this.tokens =  [];
                    resp.data.data.forEach((item)=>{
                        this.tokens.push({
                            username: item.username,
                            token: item.token,
                            phone_mask: item.phone_mask,
                            cnpj: item.cnpj,
                            company_name: item.company_name,
                            fantasy_name: item.fantasy_name,
                            expires_in: item.expires_in,
                        });
                    });
                    await this.$nextTick();
                }
            }
            catch (err) {
                this.onError(err);
            }

        },        
        setAlert: function (message){
            this.msg=message;
            this.showAlert=(message !== undefined);
        },        
        onError(err){
            if (err.response && err.response.data) {
                if (err.response.data.auth===false) {
                    localStorage.removeItem('user-token');
                    localStorage.removeItem('user-data');
                    this.$router.push('/admin/login');
                } else if (err.response.data.erro){
                    this.setAlert(err.response.data.erro); 
                } else {
                    this.setAlert(err.message);
                }
            } else if(typeof(err)==='string') { 
                this.setAlert(err);
            } else {
                this.setAlert(err.message);
            }
        },
        onResize () {
            this.isMobile = window.innerWidth < 576;
            this.isFullDesktop = window.innerWidth > 1300;
        },
        calculateRemainingMinutes(expires_in) {
            const now = new Date();
            const expiresIn = new Date(expires_in);
            const differenceInMilliseconds = expiresIn - now;
            return Math.floor(differenceInMilliseconds / 1000 / 60);
        },
    },
    mounted () {
        this.getData();
        this.onResize();
        window.addEventListener('resize', this.onResize, { passive: true });
    },
}
</script>

<style>

</style>