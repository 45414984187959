var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',{staticClass:"justify-content-center"},[_c('CModal',{attrs:{"title":"Aviso!","color":"danger","show":_vm.showAlert},on:{"update:show":function($event){_vm.showAlert=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"danger"},on:{"click":function($event){_vm.showAlert = false}}},[_vm._v("OK")])]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.msg)+" ")]),_c('CCol',{attrs:{"sm":"12"}},[_c('CCard',[_c('CCardHeader',[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('h4',{staticClass:"card-title mb-0",attrs:{"id":"traffic"}},[_vm._v("Tokens")]),_c('div',{staticClass:"small text-muted"},[_vm._v("Todos os tokens ativos de clientes")])])],1)],1),_c('CCardBody',[_c('CRow',{staticClass:"align-items-center"},[_c('CCol',{staticClass:"mb-3 mb-xl-3",attrs:{"sm":"12"}},[_c('CRow',[_c('CCol',{attrs:{"sm":"6","md":"4"}},[_c('CButton',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.getData();}}},[_vm._v("Atualizar")])],1)],1)],1)],1),_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CDataTable',{staticClass:"mb-0 table-outline",attrs:{"hover":"","head-color":"light","items":_vm.tokens,"fields":_vm.tableFields,"noItemsView":_vm.noItemsView},scopedSlots:_vm._u([{key:"username",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.username)+" ")])}},{key:"token",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.token)+" ")])}},{key:"phone_mask",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.phone_mask)+" ")])}},{key:"cnpj",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(_vm.formatCNPJ(item.cnpj))+" ")])}},{key:"company_name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.company_name)+" ")])}},{key:"fantasy_name",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(item.fantasy_name)+" ")])}},{key:"expires_in",fn:function(ref){
var item = ref.item;
return _c('td',{},[_vm._v(" "+_vm._s(_vm.calculateRemainingMinutes(item.expires_in))+" minutos. ")])}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }